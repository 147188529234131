body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-fab {
  position: fixed;
  bottom: 25px;
  right: 25px;
}

ul.timeline {
  list-style-type: none;
  position: relative;
}

ul.timeline::before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}

ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}

ul.timeline > li::before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid blue;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

li.list-alert::before {
  border-color: red!important;
}

li.list-warning::before {
  border-color: orange!important;
}

li.list-success::before {
  border-color: green!important;
}

ul.innerlist {
  list-style-type: none;
}

.btnblue {
  background-color: #22c0e8!important;
  color: white!important;
}

.icon-white {
  color: white;
}

.fabolt {
  background-color: #ffa300;
}

.fatint {
  background-color: #00e497;
}

.fathermometer-air {
  background-color: #cad6da;
}

.fathermometer-pool-air {
  background-color: #bcf4f7;
}

.fathermometer-water {
  background-color: #00d4ff;
}

.fathermometer-changingroom {
  background-color: #537fe6;
}

.card-size {
  height: 8rem;
}

.icon-wrapper {
  width: 4rem;
  height: 4rem;
}

.icon-wrapper svg {
  font-size: 2rem;
  position: relative;
  top: calc(50% - 1rem);
}

.rounded-circle {
  border-radius: 50% !important;
}

.progress-background {
  -webkit-animation: rotator 1.5s ease-in-out infinite;
  position: fixed;
  top: calc(50% - 6rem);
  right: 50%;
}

.progress-over {
  position: fixed;
  top: calc(50% - 6rem);
  right: 50%;
}

@-webkit-keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}