.widget {
  background-color: grey;

  width: 45%;
  height: 10rem;

  display: flex;
  justify-content: center;
}

ul.innerlist>* {
  font-size: 13px;
}